import React, { useState, useEffect } from 'react'
import { ArrowLeft, Download, Search } from 'lucide-react';
import { ChevronRight, ChevronDown } from 'lucide-react'
import { DesktopHeader } from '../../../../components/partials/desktopHeader'
import { MobileHeader } from '../../../../components/partials/mobileHeader'
import axios from 'axios'
import { Link } from 'react-router-dom';
import withAuth from '../../../../app/authCheck';
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { attendanceReport, baseURL, reportExport, reportUsers } from '../../../../utils/api';


function AttendanceReport() {
    const [activeTab, setActiveTab] = useState('Report')
    const [userList, setUserList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [isExportEnabled, setIsExportEnabled] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [loading, setLoading] = useState(true);

    const usersPerPage = 20;

    const location = useLocation();
    const state: any | null = location.state as any | null;

    const navigate = useNavigate();

    const accessToken = typeof window !== 'undefined' ? localStorage.getItem('token') || '' : ''

    const handleSearch = (e: any) => {
        setSearchTerm(e.target.value)
        setCurrentPage(1);
    }

    const fetchReportDetails = async (withParams = false) => {
        setLoading(true);
        try {
            const response = await attendanceReport(
                accessToken,
                withParams ? startDate : '',
                withParams ? endDate : ''
            );
            setUserList(response.data.data || []);
        } catch (err) {
            console.error('Error fetching report details:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReportDetails(false);
    }, []);



    // const handleExport = async () => {
    //     try {
    //         const response = await attendanceReport(accessToken, startDate, endDate, 'excel');
    //         // Handle file download here if API returns a downloadable URL or data
    //         console.log('Exporting file:', response);
    //     } catch (err) {
    //         console.error('Error exporting report:', err);
    //     }
    // };


    
    const handleExport = () => {
        const exportUrl = `${baseURL}/getAverageAttendance?startDate=${startDate}&endDate=${endDate}&download=excel`;
        window.location.href = exportUrl; 
    };
    

    return (
        <>
            <DesktopHeader activeTab={activeTab} setActiveTab={setActiveTab} />

            <div className="min-h-screen bg-white text-black">
                <div className="max-w-4xl mx-auto bg-white md:shadow-lg">
                    {/* Header */}
                    <div className="flex justify-between items-center p-4">
                        <button
                            onClick={() => navigate(-1)}
                            className="text-blue-500 hover:text-blue-700 flex items-center"
                        >
                            <ArrowLeft className="h-5 w-5 mr-4" />
                            <p className="text-black font-medium">Attendance</p>
                        </button>
                        <div>
                            <button
                                onClick={handleExport} // Call handleExport on click
                                className="text-gray-600 hover:text-gray-800 flex items-center"
                       
                            >
                                <Download className="h-5 w-5 text-blue-400 mr-1" />
                                Export
                            </button>
                        </div>
                    </div>

                    {/* Search Bar */}
                    {/* <div className="p-4">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search users..."
                                className="w-full pl-10 pr-4 py-2 border rounded-md"
                                value={searchTerm}
                                onChange={handleSearch} // Update search term on change
                            />
                            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        </div>
                    </div> */}

                    <div className="flex mb-4 justify-end mr-3">
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="border p-2 mr-2"
                        />
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="border p-2 mr-2"
                        />
                        <button onClick={() => fetchReportDetails(true)} className="px-4 py-2 bg-blue-500 text-white rounded">
                            Filter
                        </button>
                       
                    </div>

                    {/* Users Table */}
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="px-4 py-2 text-left">#</th>
                                    <th className="px-4 py-2 text-left">Event Type</th>
                                    <th className="px-4 py-2 text-left">Average Attendance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan={3} className="text-center p-4">
                                            <svg
                                                className="animate-spin h-10 w-10 text-blue-500 mx-auto"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12c0-1.16.17-2.27.48-3.32l1.63.64C6.24 9.48 6 10.7 6 12s.24 2.52.68 3.68l-1.63.64C4.17 14.27 4 13.16 4 12z"
                                                />
                                            </svg>
                                        </td>
                                    </tr>
                                ) : userList.length > 0 ? (
                                    userList.map((user:any, index) => (
                                        <tr
                                            key={index}
                                            className="border-t cursor-pointer hover:bg-gray-50"
                                        >
                                            <td className="px-4 py-2">{index + 1}</td>
                                            <td className="px-4 py-2">{user.event_type}</td>
                                            <td className="px-4 py-2">{user.average_attendance}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={3} className="px-4 py-2 text-center">
                                            No attendance found.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* Mobile Navigation */}
            <MobileHeader activeTab={activeTab} setActiveTab={setActiveTab} />

        </>

    )

}

export default withAuth(AttendanceReport);

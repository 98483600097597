import React, { useState, useEffect } from 'react'
import { ArrowLeft, Download, Search } from 'lucide-react';
import { ChevronRight, ChevronDown } from 'lucide-react'
import { DesktopHeader } from '../../../../components/partials/desktopHeader'
import { MobileHeader } from '../../../../components/partials/mobileHeader'
import axios from 'axios'
import { Link } from 'react-router-dom';
import withAuth from '../../../../app/authCheck';
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { baseURL } from '../../../../utils/api';
import { upcomingEvents,upcomingEventsExport } from '../../../../utils/api';
import { useUpcomingEvents } from '../../../..//hooks/useEvents';



function EventReport() {
    const [activeTab, setActiveTab] = useState('Report')
    const [eventList, setEventList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [isExportEnabled, setIsExportEnabled] = useState(false);
    const [search_name, setSearch_name] = useState('');
    const [from_date, setFrom_date] = useState('');
    const [to_date, setTo_date] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [userCount, setUserCount] = useState(null)


    const token = typeof window !== 'undefined' ? localStorage.getItem('token') || '' : '';
  
    const { data: fetchedEvents, isLoading: isFetching, error: fetchError, refetch} = useUpcomingEvents(token, page, from_date, to_date, search_name);
    
    useEffect(() => {
        if (fetchedEvents) {
          setUserCount(fetchedEvents.totalcount); // Total number of records
          setTotalPages(fetchedEvents.pagecount); // Calculate total pages (assuming 10 records per page)
        }
      }, [fetchedEvents]);
    
    useEffect(() => {
        refetch(); // This will trigger a new API call
      }, [page,search_name, refetch,from_date,to_date]);


    const [loading, setLoading] = useState(true);
    console.log(eventList,"test")
    const usersPerPage = 50;


    const location = useLocation();
    const state: any | null = location.state as any | null;

    const navigate = useNavigate();
    const accessToken = typeof window !== 'undefined' ? localStorage.getItem('token') || '' : ''
    const handleSearch = (e: any) => {
        setSearch_name(e.target.value)
        setPage(1);
    }

    const fetchReportDetails = async (withParams = false,page = 1, searchTerm = '') => {
        setLoading(true);
        try {
            const response = await upcomingEvents(
                accessToken,
                withParams ? page : 1,
                withParams ? from_date : '',
                withParams ? to_date : '',
                withParams ? search_name : '',

            );
            setEventList(response.upcoming_events || []);
            setPage(response.data.current_page || 1);
            setUserCount(response.data.totalcount || 0);
            


        } catch (err) {
            console.error('Error fetching report details:', err);
        } finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     fetchReportDetails(false);
    // }, []);


    // useEffect(() => {
    //     fetchReportDetails(currentPage, search_name); // Fetch report details when slug, page, or searchTerm changes
    // }, [ currentPage, search_name]);


    useEffect(() => {
        fetchReportDetails(true,page, search_name); // Correct order
    }, [page, search_name]);
    


    // const handleExport = async () => {
    //     try {
    //         const response = await attendanceReport(accessToken, startDate, endDate, 'excel');
    //         // Handle file download here if API returns a downloadable URL or data
    //         console.log('Exporting file:', response);
    //     } catch (err) {
    //         console.error('Error exporting report:', err);
    //     }
    // };



    
    
    const handleExport = () => {
        const exportUrl = `${baseURL}/upcomingEventsExport?from_date=${from_date}&to_date=${to_date}&search_name=${search_name}`;
        window.location.href = exportUrl; 
    };
    

    return (
        <>
            <DesktopHeader activeTab={activeTab} setActiveTab={setActiveTab} />

            <div className="min-h-screen bg-white text-black">
                <div className="max-w-4xl mx-auto bg-white md:shadow-lg">
                    {/* Header */}
                    <div className="flex justify-between items-center p-4">
                        <button
                            onClick={() => navigate(-1)}
                            className="text-blue-500 hover:text-blue-700 flex items-center"
                        >
                            <ArrowLeft className="h-5 w-5 mr-4" />
                            <p className="text-black font-medium">Event Report</p>
                        </button>
                        <div>
                            <button
                                onClick={handleExport} // Call handleExport on click
                                className="text-gray-600 hover:text-gray-800 flex items-center"
                       
                            >
                                <Download className="h-5 w-5 text-blue-400 mr-1" />
                                Export
                            </button>
                        </div>
                    </div>

                   


                    {/* Search Bar */}
                    <div className="p-4">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search Event Name/Type,Church Name,Leader Name..."
                                className="w-full pl-10 pr-4 py-2 border rounded-md"
                                value={search_name}
                                onChange={handleSearch} // Update search term on change
                            />
                            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                            
                        </div>
                    </div>


                    <div className="flex mb-4 justify-end mr-3">
                        <input
                            type="date"
                            value={from_date}
                            onChange={(e) => setFrom_date(e.target.value)}
                            className="border p-2 mr-2"
                        />
                        <input
                            type="date"
                            value={to_date}
                            onChange={(e) => setTo_date(e.target.value)}
                            className="border p-2 mr-2"
                        />
                        <button onClick={() => fetchReportDetails(true)} className="px-4 py-2 bg-blue-500 text-white rounded">
                            Filter
                        </button>
                       
                    </div>

                    {/* Users Table */}
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="px-4 py-2 text-left">#</th>
                                    <th className="px-4 py-2 text-left">Date</th>
                                    <th className="px-4 py-2 text-left">Time</th>

                                    {/* <th className="px-4 py-2 text-left">Time</th> */}
                                    <th className="px-4 py-2 text-left">Event Name</th>
                                    <th className="px-4 py-2 text-left">Event Type</th>
                                    <th className="px-4 py-2 text-left">Leader</th>
                                    <th className="px-4 py-2 text-left">Church</th>

                                    <th className="px-4 py-2 text-left">Attendance</th>




                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan={3} className="text-center p-4">
                                            <svg
                                                className="animate-spin h-10 w-10 text-blue-500 mx-auto"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12c0-1.16.17-2.27.48-3.32l1.63.64C6.24 9.48 6 10.7 6 12s.24 2.52.68 3.68l-1.63.64C4.17 14.27 4 13.16 4 12z"
                                                />
                                            </svg>
                                        </td>
                                    </tr>
                            ) : eventList.length > 0 ? (
                                eventList.map((user: any, index: number) => {
                                    const serialNumber = (page - 1) * usersPerPage + index + 1;
                            
                                    return (
                                        <tr key={index} className="border-t cursor-pointer hover:bg-gray-50">
                                            <td className="px-4 py-2">{serialNumber}</td> {/* Use calculated serialNumber here */}
                                            <td className="px-4 py-2" style={{ whiteSpace: 'nowrap' }} >{user.EventDate}</td>
                                            <td className="px-4 py-2"  style={{ whiteSpace: 'nowrap' }}>{user.EventTime}</td>
                                            <td className="px-4 py-2">{user.EventName}</td>
                                            <td className="px-4 py-2">{user.EventType}</td>
                                            <td className="px-4 py-2">{user.EventLeader}</td>
                                            <td className="px-4 py-2">{user.EventChurchName}</td>

                                            <td className="px-4 py-2">{user.totalattendance}</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={6} className="px-4 py-2 text-center">
                                        No Events found.
                                    </td>
                                </tr>
                            )}
                            </tbody>
                            
                        </table>




                        <div className="flex justify-between mt-4 mb-[30px]">
                            <button
                                disabled={page === 1}
                                onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                                className={`px-4 py-2 ${page === 1 ? 'bg-gray-200' : 'bg-blue-500 text-white'}`}
                            >
                                Previous
                            </button>

                            <span className="px-4 py-2">
                            Page {page} of {totalPages} from {userCount} Records
                            </span>

                            <button
                                onClick={() => setPage((prev) => prev + 1)}
                                disabled={!fetchedEvents?.has_more_pages}

                                className={`px-4 py-2 ${fetchedEvents?.has_more_pages ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                                >
                                Next
                            </button>
                        </div>



                          {/* Pagination controls */}
            {/* <div className="flex justify-between items-center mt-4 mb-[150px]">
              <button
                onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                disabled={page === 1}
                className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50"
              >
                Previous
              </button>
              <span>Page {page}</span>
              <button
                onClick={() => setPage((prev) => prev + 1)}
                disabled={!fetchedEvents?.has_more_pages}
                className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50"
              >
                Next
              </button>
            </div> */}
                    </div>
                </div>
            </div>
            {/* Mobile Navigation */}
            <MobileHeader activeTab={activeTab} setActiveTab={setActiveTab} />

        </>

    )

}

export default withAuth(EventReport);

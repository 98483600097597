import React, { useState, useEffect, useRef } from 'react';
import { DesktopHeader } from '../../../components/partials/desktopHeader';
import { MobileHeader } from '../../../components/partials/mobileHeader';
import NewEvent from './create/page'; // Adjust the import as necessary
import { useUpcomingEvents, useDeleteEvent } from '../../../hooks/useEvents';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate } from 'react-router-dom'; // Use React Router for navigation
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../redux/store';
import { fetchEventsSuccess } from '../../../redux/slices/eventSlice';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import withAuth from '../../../app/authCheck';
import Lottie from 'lottie-react';
import emptyAnimation from '../../../animation/empty.json';
import { MoreVertical, Plus, Search } from 'lucide-react';

const MySwal = withReactContent(Swal);

function ChurchEvents() {
  const [activeTab, setActiveTab] = useState('Events');
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<number | null>(null);
  const [userType, setUserType] = useState('');
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search_name, setSearch_name] = useState('');
    const [from_date, setFrom_date] = useState('');
    const [to_date, setTo_date] = useState('');

    const [totalPages, setTotalPages] = useState(1);
    const [userCount, setUserCount] = useState(null)


  console.log("p", page)

  const token = typeof window !== 'undefined' ? localStorage.getItem('token') || '' : '';
  const events = useSelector((state: RootState) => state.events.events);

  const { data: fetchedEvents, isLoading: isFetching, error: fetchError, refetch} = useUpcomingEvents(token, page, from_date, to_date, search_name);
  const { mutate: deleteEvent } = useDeleteEvent(token);

  // useEffect(() => {
  //   refetch(); // This will trigger a new API call
  // }, [page, refetch]); 


  useEffect(() => {
    refetch(); // This will trigger a new API call
  }, [page,search_name, refetch,from_date,to_date]);

  const handleNextPage = () => {
    if (fetchedEvents?.pagination_links.next_page_url) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (fetchedEvents?.pagination_links.prev_page_url) {
      setPage((prevPage) => prevPage - 1);
    }
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch_name(e.target.value);
    setPage(1); // Reset to the first page on new search
  };
  const handleMoreClick = (index: number) => {
    setSelectedEvent(selectedEvent === index ? null : index);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setSelectedEvent(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (fetchedEvents) {
      setUserCount(fetchedEvents.totalcount); // Total number of records
      // setTotalPages(Math.ceil(fetchedEvents.totalcount / 10)); // Calculate total pages (assuming 10 records per page)
      setTotalPages(fetchedEvents.pagecount);
    }
  }, [fetchedEvents]);


  useEffect(() => {
    const userData = typeof window !== 'undefined' ? localStorage.getItem('user') || '' : '';
    const parsedData = userData ? JSON.parse(userData) : null;
    const userType = parsedData?.user.UserType;
    setUserType(userType);
  }, []);

  const handleDelete = (id: number) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this event. This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete Event',
      cancelButtonText: 'Discard',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEvent(id);
        MySwal.fire('Deleted!', 'The event has been deleted.', 'success').then(() => {
          window.location.reload();
        });
      }
    });
  };


  const handleEdit = (id: number) => {
    navigate(`/dashboard/events/edit-event/${id}`); // Use navigate from React Router
  };

  const handleAttendance = (id: number) => {
    navigate(`/dashboard/events/attendance/${id}`);
  };

  useEffect(() => {
    if (!events.length && fetchedEvents) {
      dispatch(fetchEventsSuccess(fetchedEvents));
    } else if (fetchError) {
      console.error('Error fetching events:', fetchError);
    }
  }, [dispatch, events.length, fetchedEvents, fetchError]);

  const skeletonRows = Array(events?.length || 5).fill(null);

  return (
    <div className="min-h-screen bg-white flex flex-col text-black">
      {/* Desktop Header */}
      <DesktopHeader activeTab={activeTab} setActiveTab={setActiveTab} />

      {/* Main Content */}
      <main className="flex-grow container mx-auto p-4 md:p-8">
        <div></div>
        <h2 className="text-2xl font-bold mb-4">Events</h2>




        <div className="p-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search Events/Event Type/Church Name/Leader"
                className="w-full pl-10 pr-4 py-2 border rounded-md"
                value={search_name}
                onChange={handleSearch}
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>




          <div className="flex mb-4 justify-end mr-3">
                        <input
                            type="date"
                            value={from_date}
                            onChange={(e) => setFrom_date(e.target.value)}
                            className="border p-2 mr-2"
                        />
                        <input
                            type="date"
                            value={to_date}
                            onChange={(e) => setTo_date(e.target.value)}
                            className="border p-2 mr-2"
                        />
                        <button onClick={() => refetch()} className="px-4 py-2 bg-blue-500 text-white rounded">
                            Filter
                        </button>
                       
                    </div>



        <h3 className="p-4 font-semibold ml-[-20px] md:ml-[0px]">Upcoming Events</h3>
        <div className="bg-white rounded-lg  overflow-hidden">
          <ul
            className="overflow-auto"
          // style={{ height: events?.length ? `${events.length * 180}px` : '100vh' }} // Adjust height based on events
          >            {isFetching ? (
            skeletonRows.map((_, index) => (
              <li key={index} className="border-t">
                <Skeleton width={20} />
                <Skeleton width={150} />
                <Skeleton width={150} />
              </li>
            ))
          ) : (
            <>
              {fetchedEvents && fetchedEvents?.upcoming_events?.length > 0 ? (
                <ul>
                  {fetchedEvents.upcoming_events?.map((event: any, index: number) => (
                    <li key={index} className="border-t border-gray-200">
                      <div className="flex items-center justify-between p-4 relative">
                        <Link to={`/dashboard/events/attendance/${event.id}`}>
                          <div>
                            <h4 className="font-semibold">{event.EventName}</h4>
                            <p className="text-sm text-gray-500 mt-2">
                              {event.EventDate} {event.EventDay} <br />
                              {event.EventChurchName}
                            </p>
                          </div>
                        </Link>

                        <button
                          className="p-2 hover:bg-gray-100 rounded-full"
                          onClick={() => handleMoreClick(index)}
                        >
                          <MoreVertical className="h-5 w-5 text-gray-400" />
                        </button>

                        {selectedEvent === index && (
                          <div
                            ref={dropdownRef}
                            className="absolute right-0 top-10 bg-white shadow-lg rounded-md p-2 z-[999]"
                          >
                            <button
                              onClick={() => handleAttendance(event.id)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              Add Attendance
                            </button>
                            <button
                              onClick={() => handleEdit(event.id)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              Edit
                            </button>
                            {userType === "Admin" && (
                              <button
                                onClick={() => handleDelete(event.id)}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                Delete
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <Lottie
                  animationData={emptyAnimation}
                  loop={true}
                  style={{ width: '100%', height: '300px' }}
                />
              )}

            </>
          )}


{/* 
<div className="flex justify-between mt-4 mb-[30px]">
                            <button
                                disabled={page === 1}
                                onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                                className={`px-4 py-2 ${page === 1 ? 'bg-gray-200' : 'bg-blue-500 text-white'}`}
                            >
                                Previous
                            </button>

                            <span className="px-4 py-2">
                            Page {page} of {totalPages} from {userCount} Records
                            </span>

                            <button
                                onClick={() => setPage((prev) => prev + 1)}
                                disabled={!fetchedEvents?.has_more_pages}
                                className={`px-4 py-2 ${page === totalPages ? 'bg-gray-200' : 'bg-blue-500 text-white'}`}
                            >
                                Next
                            </button>
                        </div> */}


<div className="flex justify-between mt-4 mb-[30px]">
                            <button
                                disabled={page === 1}
                                onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                                className={`px-4 py-2 ${page === 1 ? 'bg-gray-200' : 'bg-blue-500 text-white'}`}
                            >
                                Previous
                            </button>

                            <span className="px-4 py-2">
                            Page {page} of {totalPages} from {userCount} Records
                            </span>

                            <button
                                onClick={() => setPage((prev) => prev + 1)}
                                disabled={!fetchedEvents?.has_more_pages}

                                className={`px-4 py-2 ${fetchedEvents?.has_more_pages ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                                >
                                Next
                            </button>
                        </div>



            {/* Pagination controls */}
            {/* <div className="flex justify-between items-center mt-4 mb-[150px]">
              <button
                onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                disabled={page === 1}
                className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50"
              >
                Previous
              </button>
              <span>Page {page}</span>
              <button
                onClick={() => setPage((prev) => prev + 1)}
                disabled={!fetchedEvents?.has_more_pages}
                className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50"
              >
                Next
              </button>
            </div> */}
          </ul>
        </div>
      </main>

      {/* Floating Action Button */}
      <div className="fixed bottom-20 right-4 md:bottom-8 md:right-8">
        <button onClick={() => setIsCreateOpen(true)} className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-4 shadow-lg">
          <Plus className="h-6 w-6" />
        </button>
      </div>

      <div
        className={`fixed bottom-0 left-0 right-0 bg-white shadow-lg transition-transform transform ${isCreateOpen ? 'translate-y-0' : 'translate-y-full'
          } ease-out duration-300`}
        style={{ height: '100%' }}
      >
        <NewEvent onClose={() => setIsCreateOpen(false)} />
      </div>

      {/* Mobile Navigation */}
      <MobileHeader activeTab={activeTab} setActiveTab={setActiveTab} />
    </div>
  );
}

export default withAuth(ChurchEvents);

